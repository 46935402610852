<template>
  <div class="home">
    <center>
      <pre>
╔═════════════════════════════════════╗
║SYS://MSNGR                       X  ║
╠═════════════════════════════════════╣
║MESSAGE FROM USER:                   ║
║"CYN"                                ║
║                                     ║
║               Hello  :)             ║
║                                     ║
║                                     ║
╚═════════════════════════════════════╝
      </pre>
    </center>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {}
}

</script>
<style>
pre{
  margin-top: 30vh;
  color: yellow;
  font-size: 24px;
  text-align: center;
  width: 100%;
  position: fixed;
}

body{
  background-color: black;
}
@media (max-width: 480px) {
  pre{
  margin-top: 30vh;
  color: yellow;
  font-size: 16px;
  text-align: center;
  width: 100%;
  position: fixed;
}

}
</style>